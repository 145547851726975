<template>
  <section class="home">
    <div class="header">
      <div class="content">
        <img class="back" src="../assets/img/header/logo.png" alt="" />
        <img class="white" src="../assets/img/home/logo.png" alt="" />
        <div class="right">
          <div v-for="(item, index) in routesList" class="item" :class="[index == 0 && 'activeItem']" :key="index" @click="toPage(item.path)">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <img v-show="activeIndex === 0" :src="bannerList[activeIndex].banner" alt="" class="background" />
      <img v-show="activeIndex === 1" :src="bannerList[activeIndex].banner" alt="" class="background" />
      <img v-show="activeIndex === 2" :src="bannerList[activeIndex].banner" alt="" class="background" />
      <img v-show="activeIndex === 3" :src="bannerList[activeIndex].banner" alt="" class="background" />
      <!-- <div class="bannerContent_" v-show="activeIndex === 0">
        <div class="bannerTitle animate__animated animate__fadeInUp animate__delay-1s">{{ bannerList[activeIndex].title1 }}</div>
        <div class="bannerTitle animate__animated animate__fadeInUp animate__delay-2s">{{ bannerList[activeIndex].title2 }}</div>
        <div class="bannerContent animate__animated animate__fadeInUp animate__delay-3s">{{ bannerList[activeIndex].content }}</div>
      </div> -->
      <div class="bannerContent_">
        <div class="bannerOtherTitle animate__animated animate__fadeInUp animate__delay-1s">
          <span>{{ bannerList[activeIndex].title1 }}</span>
          <span>{{ bannerList[activeIndex].title2 }}</span>
        </div>
        <div class="bannerOtherContent">
          <p class="animate__animated animate__fadeInUp animate__delay-2s">
            {{ bannerList[activeIndex].content1 }}
          </p>
          <p class="animate__animated animate__fadeInUp animate__delay-3s">
            {{ bannerList[activeIndex].content2 }}
          </p>
        </div>
      </div>
    </div>
    <div class="aboutBox">
      <div class="about">
        <div class="item" :key="index" v-for="(item, index) in aboutList">
          <div class="itemTitle">
            <img :src="item.image" alt="" />
            <div>
              <div>{{ item.en }}</div>
              <div>{{ item.ch }}</div>
            </div>
          </div>
          <div class="itemContent">{{ item.content }}</div>
          <div class="itemButton" @click="toProduct">了解产品 ></div>
        </div>
      </div>
    </div>
    <div class="company" ref="company">
      <img v-show="companyShow" class="animate__animated animate__fadeInRight" src="@/assets/img/home/company.jpg" alt="" />
      <div v-show="companyShow" class="companyInfo animate__animated animate__fadeInRight animate__delay-1s">
        <div class="companyTitle">公司介绍</div>
        <div class="companyContent">
          浙江特磊网络科技有限公司成立于2020年，专注于出口贸易企业的供应链管理系统的技术研发及服务，解决企业全球化进程中的供应链管理问题。特磊秉承立足中国、放眼全球的理念，以科学、严谨的态度，为企业出海提供强有力的科技服务。全贸通供应链管理系统为出口贸易企业提供轻量化服务，让您的企业花更少的钱，办更大的事。
        </div>
        <div class="companyContent">特磊，专注出口贸易供应链管理服务的方案商。</div>
      </div>
    </div>
    <div class="client">
      <div class="clientTitle">
        <div>服务客户</div>
      </div>
      <div class="clientContent">
        <img src="@/assets/img/home/client1.jpg" alt="" />
        <img src="@/assets/img/home/client2.jpg" alt="" />
        <img src="@/assets/img/home/client3.jpg" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      aboutList: [
        {
          image: require("@/assets/img/home/item1.png"),
          en: "CDM",
          ch: "基础管理",
          content: "七大类基础数据管理：覆盖商品、竞品、供应商、客户、仓库、库位、货柜等，高效建立业务基础数据模型。",
          path: "",
        },
        {
          image: require("@/assets/img/home/item2.png"),
          en: "OMS",
          ch: "订单管理",
          content: "全链路可视化订单跟踪：实现采购订单，销售订单，物流单等全流程管理，使业务人员和客户能有效同步订单执行情况，各环节有效协同。",
          path: "",
        },
        {
          image: require("@/assets/img/home/item3.png"),
          en: "WMS",
          ch: "仓库管理",
          content:
            "专注供应链管理，解决出口贸易企业的难点痛点。以科学、严谨的态度，对采、销、转、存业务环节进行深度改造、融合，实现软件“数智化”，从而达到企业降本增效的目的。",
          path: "",
        },
        {
          image: require("@/assets/img/home/item4.png"),
          en: "Receipt",
          ch: "发票开具",
          content: "多套财税接口：基于欧洲、南美智利等多国税收法规，打通业务、财务、税务数据壁垒，实现业财一体化，减轻企业涉税问题的人力、物力支出。",
          path: "",
        },
      ],
      routesList: [
        {
          path: "/home",
          name: "首页",
        },
        {
          path: "/product",
          name: "产品介绍",
        },
        {
          path: "/case",
          name: "案例展示",
        },
        {
          path: "/about",
          name: "联系我们",
        },
      ],
      bannerList: [
        {
          banner: require("@/assets/img/home/banner1.jpg"),
          title1: "聚焦出口",
          title2: "",
          content1: "聚焦出口贸易业务，系统提供采购、仓储、运输、批发、报关业务的一站式服务功能",
          content2: "为出口贸易企业降本增效",
        },
        // {
        //   banner: require("@/assets/img/home/banner2.jpg"),
        //   title1: "国贸城-",
        //   title2: "美事达仓储",
        //   content: "聚百货精品 创服务大成",
        // },
        // {
        //   banner: require("@/assets/img/home/banner3.jpg"),
        //   title1: "国贸城-",
        //   title2: "美事达仓储",
        //   content: "聚百货精品 创服务大成",
        // },
        {
          banner: require("@/assets/img/home/banner4.jpg"),
          title1: "小而精",
          title2: "",
          content1: "以小成本实现高利润",
        },
      ],
      activeIndex: 0,
      companyShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    setInterval(() => {
      this.activeIndex++;
      if (this.activeIndex === 2) {
        this.activeIndex = 0;
      }
    }, 4000);
  },
  methods: {
    toProduct() {
      this.$router.push({ name: "product" });
    },
    handleScroll() {
      // 实现当滚动到指定位置，触发动画
      // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
      // this.gdjz('earth', 'earth animated bounceInDown', 50)
      let _this = this;
      let refArray = [{ ref: "company", show: "companyShow" }];
      refArray.forEach((r) => {
        _this.gdjz(r.ref, 20, () => {
          _this[r.show] = true;
        });
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        let a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
      }
    },
    toPage(path) {
      if (this.$route.path === path) return;
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    display: flex;
    height: 90px;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: transparent;
    color: white;
    .back {
      display: none;
    }
    .white {
      display: block;
    }
    &:hover {
      background: white;
      color: black;
      .back {
        display: block;
      }
      .white {
        display: none;
      }
      .activeItem {
        font-weight: bold;
        background: #f6f6f6;
        color: #1b3a81;
      }
    }
    .content {
      display: flex;
      height: inherit;
      justify-content: space-between;
      align-items: center;
      width: 1436px;
      img {
        height: 72px;
      }
      .right {
        margin-left: auto;
        display: flex;
        .item {
          height: 90px;
          padding: 0 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
        }
      }
    }
  }
  .banner {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding-top: 235px;
    position: relative;
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      animation: zoomInBig 1s;
    }
    .bannerTitle {
      font-size: 80px;
      color: white;
    }
    .bannerContent {
      margin-top: 80px;
      font-size: 24px;
      line-height: 45px;
      color: white;
      width: 690px;
    }
    .bannerOtherTitle {
      font-size: 72px;
      color: white;
      margin-top: 100px;
      //margin-left: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      span:last-child {
        color: red;
      }
      img {
        height: 80px;
      }
    }
    .bannerOtherContent {
      margin-top: 30px;
      color: white;
      font-size: 30px;
      text-align: center;
      & > p {
        margin: 5px 30px;
      }
    }
  }
  .aboutBox {
    background: #f6f6f6;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .about {
      display: flex;
      justify-content: space-between;
      width: 1436px;
      padding-bottom: 100px;
      padding-top: 90px;
      .item {
        width: 342px;
        height: 410px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 55px;
        padding-bottom: 44px;
        background: white;
        .itemTitle {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          flex-shrink: 0;
          padding-right: 30px;
          img {
            width: 38px;
            height: 38px;
            margin-right: 20px;
          }
        }
        .itemContent {
          flex-grow: 1;
          padding: 22px 26px 0;
          line-height: 2;
        }
        .itemButton {
          width: 118px;
          height: 44px;
          background: #ffffff;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #1b3a81;
          font-size: 16px;
          color: #1b3a81;
        }
      }
    }
  }
  .company {
    padding: 113px 0;
    width: 1436px;
    display: flex;
    img {
      width: 560px;
      height: 460px;
      margin-right: 130px;
    }
    .companyInfo {
      padding-top: 50px;
      .companyTitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 100px;
      }
      .companyContent {
        font-size: 18px;
        line-height: 2;
        &:last-child {
          margin-top: 35px;
        }
      }
    }
  }
  .client {
    padding-top: 42px;
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .clientTitle {
      height: 2px;
      font-size: 30px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #cccccc;
      width: 435px;
      margin-bottom: 92px;
      div {
        background: white;
        padding: 0 20px;
      }
    }
    .clientContent {
      display: flex;
      width: 1436px;
      justify-content: space-between;
      img {
        width: 440px;
        height: 293px;
        &:hover {
          transform: scale(1.1);
          transition: all 1s;
        }
      }
    }
  }
}
@keyframes zoomInBig {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
